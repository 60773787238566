import React from 'react'
import TrackingContextProvider from '../TrackingContextProvider/TrackingContextProvider'
import { GlobalTrackingContext } from '../useGlobalTracking/useGlobalTracking'

type FlattenedTrackProperties = {
  [key: string]: string
}

type GlobalTrackingContextProviderProps = {
  siteName: string
  siteProperties?: { [key: string]: string }
  children: React.ReactNode
  trackFn: (name: string, properties: FlattenedTrackProperties) => void
  trackPageFn: (name: string, properties: FlattenedTrackProperties) => void
}

// Wrap the entire frontend application in this provider so that all components using the
// TrackingContextProvider can access the track and trackPage functions.
const GlobalTrackingContextProvider = ({
  children,
  siteName,
  siteProperties,
  trackFn,
  trackPageFn,
}: GlobalTrackingContextProviderProps) => (
  <GlobalTrackingContext.Provider
    value={{
      trackFn,
      trackPageFn,
    }}
  >
    <TrackingContextProvider
      name={siteName}
      initialTrackingProperties={siteProperties}
    >
      {children}
    </TrackingContextProvider>
  </GlobalTrackingContext.Provider>
)

GlobalTrackingContextProvider.defaultProps = {
  siteProperties: null,
}

export default GlobalTrackingContextProvider

import { GlobalTrackingContextProvider } from '@flock/flock-component-library'
import { OvermoonThemeProvider, overmoonTheme } from '@flock/shared-ui'
import { CssBaseline, ThemeProvider } from '@mui/material'
import React from 'react'
import { track, trackPage } from './analytics'
import ConfiguredApolloProvider from './apollo'

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }: any) => (
  <GlobalTrackingContextProvider
    siteName="overmoon-landing-fe"
    trackFn={track}
    trackPageFn={trackPage}
  >
    <ThemeProvider theme={overmoonTheme}>
      <OvermoonThemeProvider>
        <CssBaseline />
        <ConfiguredApolloProvider>{element}</ConfiguredApolloProvider>
      </OvermoonThemeProvider>
    </ThemeProvider>
  </GlobalTrackingContextProvider>
)

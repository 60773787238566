// These set up types for segment analytics
/// <reference types="@types/segment-analytics" />

import { localStore } from '@flock/utils'

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
  }
}

// If logSegment is true, events are not sent to segment and are
// instead logged to the console.
const logSegment = process.env.GATSBY_LOG_SEGMENT === 'true'

const debugSegment = () =>
  logSegment || window.location.href.includes('localhost')

let shouldNotTrack = false
if (typeof window !== 'undefined' && !debugSegment()) {
  shouldNotTrack =
    window.doNotTrack === '1' ||
    navigator.doNotTrack === 'yes' ||
    navigator.doNotTrack === '1' ||
    localStore?.getItem('disableTracking') === 'true'
}

export const shouldTrack = () => {
  let doNotTrack = false
  if (typeof window !== 'undefined') {
    doNotTrack =
      window.doNotTrack === '1' ||
      navigator.doNotTrack === 'yes' ||
      navigator.doNotTrack === '1' ||
      localStore?.getItem('disableTracking') === 'true' ||
      debugSegment()
  }
  return !doNotTrack
}

export const track = (name: string, properties?: any) => {
  const savedParamsString = localStore?.getItem('queryParams') || '{}'
  const savedParams = JSON.parse(savedParamsString)

  if (shouldNotTrack) {
    return
  }

  if (debugSegment()) {
    console.log('Segment Track', name, {
      ...savedParams,
      ...properties,
    })
  } else {
    // @ts-ignore
    if (window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({ event: name })
    }

    window.analytics.track(name, {
      ...savedParams,
      ...properties,
    })
  }
}

export const trackPage = (name: string, properties: any) => {
  let queryParams: { [k: string]: string } = {}
  if (typeof window !== 'undefined') {
    const { search } = window.location
    const params = new URLSearchParams(search)
    params.forEach((value: string, key: string) => {
      queryParams[key] = value
    })
  }

  const savedParamsString = localStore?.getItem('queryParams') || '{}'
  const savedParams = JSON.parse(savedParamsString)
  queryParams = {
    ...savedParams,
    ...queryParams,
  }
  localStore?.setItem('queryParams', JSON.stringify(queryParams))

  if (shouldNotTrack) {
    return
  }

  if (debugSegment()) {
    console.log('Segment Page', name, properties)
  } else {
    window.analytics.page({
      title: name,
      url: `${window.location.origin}/${name}`,
      path: `/${name}`,
      search: `${window.location.search}`,
    })
  }
  track(name, {
    ...properties,
    ...queryParams,
  })
}

export type IdentifyProperties = {
  userId?: string
  email?: string
  name?: string
  phone?: string
  brokerUuid?: string
  utmSource?: string
  utmCampaign?: string
  utmMedium?: string
}

export const identify = (properties: IdentifyProperties) => {
  if (shouldNotTrack) {
    return
  }

  if (debugSegment()) {
    console.log('Segment Identify', properties)
  } else if (properties.userId) {
    const { userId, ...otherProperties } = properties
    window.analytics.identify(userId, {
      ...otherProperties,
    })
  } else {
    window.analytics.identify({
      ...properties,
    })
  }
}

export const getGAClientID = () => {
  // cookie is stored in this format '_ga=GA1.2.358520647.1656458798; _gcl=cookie2'
  if (typeof window !== 'undefined' && window.document.cookie !== '') {
    const match = window.document.cookie.match(/_ga=(.+?)(;|$)/)
    if (match !== null && match.length > 1) {
      return match[1].split('.').slice(-2).join('.')
    }
  }
  return ''
}

export const HOMEPAGE_URL = '/'
export const HOW_TO_SIGN_UP_URL = '/how-to-sign-up'
export const DST_URL = '/1031'
export const ABOUT_US_URL = '/about-us'
export const FAQ_URL = '/faq'
export const PRIVACY_POLICY_URL = '/privacy'
export const ONBOARDING_URL = '/onboarding'
export const CAREERS_URL = '/careers'
export const PORTFOLIO_MAILER_URL = '/portfolio-owner'
export const PORTFOLIO_URL = '/portfolio'
export const DST_PORTFOLIO_URL = '/1031/portfolio'
export const AGENTS_URL = '/agents'
export const AGENTS_LEAD_SUCCESS_URL = '/agents/address-success'
export const AGENTS_EXAMPLE_OFFER_URL = '/agents/example-offer-success'
export const AGENTS_SCHEDULE_SUCCESS = '/agents/schedule-call-success'
export const DST_LANDING_PAGE_SOURCE = 'dst_landing_page'
export const SUPPORT_EMAIL = '721exchange@overmoon.com'
export const SUPPORT_PHONE = '+14079745463'
export const SUPPORT_PRETTY_PHONE = '(407) 974-5463'
export const BLOG_URL = '/blog'
export const INVESTOR_URL = process.env.GATSBY_OVERMOON_INVESTOR_URL || '/'
export const GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY
export const CALENDLY_SCHEDULE_URL = process.env.GATSBY_CALENDLY_SCHEDULE_URL
export const DEFAULT_SALES_OPERATOR = 'Jacob Schnapp'
export const CALENDLY_LANDING_URL = process.env.GATSBY_CALENDLY_LANDING_URL
export const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_TOKEN

export const SAM_OPERATOR_UUID = '778bb9f9-464b-4766-9d87-9da403ae05ac'
export const JACOB_OPERATOR_UUID = 'f9c0e463-48a3-4b89-a7ce-437cd8cb8ad2'
export const ARI_OPERATOR_UUID = 'a7014674-a842-437e-95da-442c430697e6'
export const COLIN_OPERATOR_UUID = '55a91781-9619-4f4e-8804-05aa76c3d71c'

export const { GATSBY_ENV } = process.env
export const TENANT = process.env.GATSBY_TENANT

export const LPA_URL =
  'https://overmoon-static-documents.s3.us-east-2.amazonaws.com/production/Overmoon+Limited+Partnership+Agreement'
export const PPM_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Flock+Private+Placement+Memorandum.pdf'
export const PROPERTY_STANDARDS_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Flock+Property+Standards.pdf'
export const OVERVIEW_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Flock+Two+Pager.pdf'
export const DST_OVERVIEW_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Flock+Homes+Overview+(1031+DST).pdf'
export const DENVER_POST_ARTICLE_URL =
  'https://www.denverpost.com/2021/04/10/denver-flock-homes-startup-real-estate/'
export const BUSINESS_INSIDER_URL =
  'https://www.businessinsider.com/this-startup-retirement-solution-single-family-rental-landlords-2022-3'
export const RECENT_FUND_PERFORMANCE_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Flock+Quarterly+Fact+Sheet.pdf'
export const RECENT_FUND_FINANCIALS_URL =
  'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Q2+2024+Flock+Homes+OP+LP+Financials.pdf'
export const ACQUISITION_REPORT_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Flock+Acquisition+Report.pdf'
export const TECH_CRUNCH_ARTICLE =
  'https://techcrunch.com/2022/03/24/flock-homes-closes-on-an-a16z-led-26m-series-a/'
export const ANDREESSEN_ARTICLE =
  'https://a16z.com/2022/03/24/investing-in-flock/'
export const KPMG_AUDIT_URL =
  'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Audited+2022+Flock+Homes+OP+LP+Financials.pdf'

export const GENERIC_OFFER_PAGE_DOCUMENTS = [
  {
    contentUrl:
      'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Investor+Call',
    documentType: 'performance',
    name: 'Investor Call',
    uuid: '',
  },
  {
    contentUrl:
      'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Trustpilot+Reviews+Image',
    documentType: 'educational',
    name: 'Trustpilot Reviews',
    uuid: '',
  },
  {
    contentUrl: RECENT_FUND_PERFORMANCE_URL,
    documentType: 'performance',
    name: 'Fact Sheet',
    uuid: '',
  },
  {
    contentUrl: RECENT_FUND_FINANCIALS_URL,
    documentType: 'performance',
    name: 'Fund Financials',
    uuid: '',
  },
  {
    contentUrl: KPMG_AUDIT_URL,
    documentType: 'performance',
    name: 'KPMG Audit',
    uuid: '',
  },
]
